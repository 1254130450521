import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";


const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet() ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu}/>
        </div>
        <ul>
          <li>
            <img className="mobile-nav-logo" src="/img/logo.png" alt="" />
          </li>
          <li>
            <a href="/#link1" onClick={toggleMenu}>
              Link 1
            </a>
          </li>
          <li>
            <a href="/#link2" onClick={toggleMenu}>
              Link 2
            </a>
          </li>
          <li>
            <a href="/#link3" onClick={toggleMenu}>
              Link 3
            </a>
          </li>
          <li>
            <a href="/#link4" onClick={toggleMenu}>
              Link 4
            </a>
          </li>
          <li>
            <div className="social-icons">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <img className="nav-logo" src="/img/logo.png" alt="" />
          <div className="social-icons hide-800">
            <a href="https://discord.gg/mbgQwbK6">
				<img className="nav-social" src="/icons/discord.svg" alt="" />
			</a>	
          </div>
        </div>
      </nav>
      <div className="content-wrapper">
          <header className="card" id="link1">
            <div style={{ padding: "0 24px 0 24px 0" }}>
              <h3 className="text-secondary-color">Welcome to the future</h3>
              <h1 className="pb-3">CRAB KINGS</h1>
              <p className="text-secondary-color">
                Crab King's are here to conquer the world of NFTs. Yeah we heard this so many times and everything
				is priceless or comming soon. But we are here for a reason. Hint is out there. We are the key!
              </p>
            </div>
            <div>
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      
                        <Minter
                          candyMachineId={candyMachineId}
                          
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                      
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
          </header>

          <div id="link2" className="container">
            No dont look for Roadmap and also you will not find any directly dedicated website.
			And yes you will find more at Discord, but at right time.
			Crab Kings are keys and what they open I cannot tell you. But if you search you can find hint. It's out there.
			You can trade them at Solsea but my advice is keep them and check Discord time to time .) 
          </div>


          <div id="link4" className="container faq">
            <h3 style={{ padding: "0 0 24px 0" }}>WE ARE HERE! WE ARE THE KEY!</h3>
			<hr /><p></p>
            <div>
			  <h2>CRAB GREY KING</h2>
			  <p>This is main edition, but in total edition of 30 its rare anyway. Dont cry you can also use this one later. Crab Grey King is here in edition of 12 Kings</p>
			  <img src="https://grse3vh2lwt6iu2jp52lkqi3uanzzj74mskvjas5etfmu5myamka.arweave.net/NGRN1Ppdp-RTSX90tUEboBucp_xklVSCXSTKynWYAxQ" width="200" alt="" height="200"/>
			  
			  
              
			  <hr />
            </div>

            <div>
			  <h2>CRAB PINK KING</h2>
			  <p>Special uncommon edition of 5 Kings</p>
			  <img src="https://btsolhpteqzrrqgrlnygslfmnnczed43nmttvgx4wggwhgaywd2a.arweave.net/DOTlnfMkMxjA0VtwaSysa0WSD5trJzqa_LGNY5gYsPQ"  width="200" height="200" alt=""/>
              
			  <hr />
            </div>

            <div>
			  <h2>CRAB RED KING</h2>
			  <p>Medium rare edition. You have one of 4 Kings</p>
			  <img src="https://yqreox274sfb2g35mndtcb7easdd5y3otbrvjkyu3u66cl5dyhha.arweave.net/xCJHX1_kih0bfWNHMQfkBIY-426YY1SrFN094S-jwc4"  width="200" height="200" alt=""/>
              
			  <hr />
            </div>
			
			<div>
			  <h2>CRAB BLUE KING</h2>
			  <p>Rare edition of 3 Crab Kings</p>
			  <img src="https://5jwbxodlsszcwukqhg3uridoginhou5niqzp5ajxje6ryer7teoa.arweave.net/6mwbuGuUsitRUDm3SKBuMhp3U61EMv6BN0k9HBI_mRw"  width="200" height="200" alt=""/>
              
			  <hr />
            </div>
			
			<div>
			  <h2>CRAB GREEN KING</h2>
			  <p>Rare edition of 3 Crab Kings</p>
			  <img src="https://hl2ypdw2wfki5mujtt6luxozbzmels33mwao3zv4gavc2khpb43q.arweave.net/OvWHjtqxVI6yiZz8ul3ZDlhFy3tlgO3mvDAqLSjvDzc"  width="200" height="200" alt=""/>
              
			  <hr />
            </div>
			
			<div>
			  <h2>CRAB SUN KING</h2>
			  <p>Special Rare edition of 2 Kings. Those are very special!</p>
			  <img src="https://og6nirtc5nkodoqkradoyj7zjt2ifrwk2rmgkz4yzh2jkgvw2beq.arweave.net/cbzURmLrVOG6CogG7Cf5TPSCxsrUWGVnmMn0lRq20Ek"  width="200" height="200" alt=""/>
              
			  <hr />
            </div>
			
			<div>
			  <h2>Search...</h2>
			  <p>There is maybe something special out there</p>
			                
			  <hr />
            </div>
          </div>
      </div>
    </div>
  );
};

export default App;
